<template>
  <metainfo></metainfo>
  <div class="buy-plans">
    <!-- header  -->
    <header class="header-section has-header-main">
      <!-- Header main -->
      <HeaderMain></HeaderMain>
    </header>
    <!-- product  -->
<!--    <section class="section-space trending-section main-content" style="background: #F7F9FB;">-->
    <section class="section-space trending-section main-content" >
      <div class="container">
        <!-- section heading -->
        <SectionHeading classname="text-center" text="Trending Contracts"
                        content=""
                        isMargin="mb-3"></SectionHeading>
        <!-- product -->
        <ProductsContainerSix :showPage="true"></ProductsContainerSix>
      </div><!-- .container -->
    </section><!-- trending-section -->


    <!-- Footer  -->
    <Footer classname="bg-cus on-dark"></Footer>
  </div>
</template>

<script>
import {useMeta} from "vue-meta"
import HeaderMain from "@/components/n1/HeaderMain"
import Footer from "@/pages/n1/Footer"
import SectionHeading from "@/components/n1/SectionHeading"
import ProductsContainerSix from "@/components/n1/ProductsContainerSix"

export default {
  name: "MyBuyPlans",
  components: {
    HeaderMain,
    Footer,
    SectionHeading,
    ProductsContainerSix
  },
  setup() {
    useMeta({
      title: 'Cloud Mining Services | Cloud Mining | GDMining',
      description: 'Discover high-quality cryptocurrency cloud mining services at GDMining, empowering your digital assets with secure and effective mining solutions.',
      link: [
        // { rel: 'canonical', href: 'https://gdmining.com/contracts' }
      ],
      htmlAttrs: {lang: 'en', amp: true}
    })
  }
}
</script>

<style scoped>
.main-content {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 5px;
  padding-right: 5px;
}
.section-space-t, .section-space {
  padding-top: 20px;
}
.bg-cus {
  background-color: #ffe5d0;
}
</style>

